<template>
  <b-card title="Yönetim Organizasyon Şeması">
    <a v-b-modal.modal-org-chart>
      <b-img
        src="media/portal/organization_scheme.jpg"
        fluid
      />
    </a>
    <b-modal
      id="modal-org-chart"
      centered
      size="lg"
      title="Yönetim Organizasyon Şeması"
      ok-only
      ok-title="Kapat"
    >
      <b-card-text>
        <div class="text-center">
          <b-img
            src="media/portal/organization_scheme.jpg"
            fluid
          />
        </div>
        <div class="mt-1">
          <b-table-simple
            hover
            bordered
          >
            <b-thead>
              <b-tr>
                <b-th>Adı Soyadı</b-th>
                <b-th
                  width="170"
                  class="text-center"
                >
                  Özgeçmiş
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="item in organizations"
                :key="item.id"
              >
                <b-td>
                  <b-row>
                    <b-col cols="auto">
                      <b-avatar
                        v-if="item.image"
                        :src="folderUrl + item.image"
                      />
                      <b-avatar
                        v-else
                        variant="light-primary"
                        :text="item.name[0]+item.surname[0]"
                      />
                    </b-col>
                    <b-col>
                      <div>{{ item.name }} {{ item.surname }}</div>
                      <div class="text-warning">
                        <small>{{ item.title }}</small>
                      </div>
                    </b-col>
                  </b-row>
                </b-td>
                <b-td class="text-center">
                  <b-button
                    v-b-modal.modal-org-detail
                    variant="primary"
                    size="sm"
                    @click="setModal(item)"
                  >
                    <FeatherIcon icon="EyeIcon" />
                    Görüntüle
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-org-detail"
      centered
      size="lg"
      :title="modalData.name + ' ' + modalData.surname + ' - ' + modalData.title"
      ok-only
      ok-title="Kapat"
    >
      <b-card-text v-if="modalData.id !== null">
        <b-row>
          <b-col cols="auto">
            <b-avatar
              v-if="modalData.image"
              :src="folderUrl + modalData.image"
            />
            <b-avatar
              v-else
              variant="light-primary"
              :text="modalData.name[0]+modalData.surname[0]"
            />
          </b-col>
          <b-col>
            <div>{{ modalData.name }} {{ modalData.surname }}</div>
            <div class="text-warning">
              <small>{{ modalData.title }}</small>
            </div>
            <div v-html="modalData.resume" />
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BImg, BModal, BTableSimple, BThead, BTbody, BTr, BTh, BTd, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'

export default {
  name: 'Organization',
  components: {
    BCard, BCardText, BImg, BModal, BTableSimple, BThead, BTbody, BTr, BTh, BTd, BButton, BAvatar, BRow, BCol,
  },
  data() {
    return {
      folderUrl: `${this.$store.state.app.baseURL}/media/hr/employees/`,
      modalData: {
        id: null,
        title: null,
        name: null,
        surname: null,
        image: null,
        summary_resume: null,
        resume: null,
      },
    }
  },
  computed: {
    organizations() {
      return this.$store.getters['organization/getOrganizations']
    },
  },
  created() {
    this.getOrganizations()
  },
  methods: {
    setModal(item) {
      this.modalData = item
    },
    getOrganizations() {
      this.$store.dispatch('organization/organizations')
    },
  },
}
</script>
